export const regionsIsoCode = ['EG', 'AE', 'MA', 'SA', 'IQ', 'JO', 'KW', 'US'];

export const regionsPhonePrefix = {
  EG: '+20',
  AE: '+971',
  MA: '+212',
  SA: '+966',
  IQ: '+964',
  JO: '+962',
  KW: '+965',
  US: '+1',
};

export const SUPPORT_EMAIL = 'team@eksab.com';
